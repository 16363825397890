import(/* webpackMode: "eager", webpackExports: ["RootProviders"] */ "/vercel/path0/apps/web/components/root-providers.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.18_@opentelemetry+api@1.9.0_@playwright+test@1.46.1_babel-plugin-macros@3.1.0_react_qiwxbblhxhjaai53zzzgqmwfk4/node_modules/next/font/google/target.css?{\"path\":\"lib/fonts.ts\",\"import\":\"Poppins\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-sans\",\"fallback\":[\"system-ui\",\"Helvetica Neue\",\"Helvetica\",\"Arial\"],\"preload\":true,\"weight\":[\"300\",\"400\",\"500\",\"600\",\"700\"]}],\"variableName\":\"sans\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.18_@opentelemetry+api@1.9.0_@playwright+test@1.46.1_babel-plugin-macros@3.1.0_react_qiwxbblhxhjaai53zzzgqmwfk4/node_modules/next/font/google/target.css?{\"path\":\"lib/fonts.ts\",\"import\":\"Urbanist\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-heading\",\"fallback\":[\"system-ui\",\"Helvetica Neue\",\"Helvetica\",\"Arial\"],\"preload\":true,\"weight\":[\"500\",\"600\"]}],\"variableName\":\"heading\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/styles/fontawesome-all.min.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/styles/globals.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/styles/prosemirror.css");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/vercel/path0/packages/ui/src/shadcn/sonner.tsx");
