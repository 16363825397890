import { createUmamiAnalyticsService } from '@kit/umami';
 
import { createAnalyticsManager } from './analytics-manager';
import type { AnalyticsManager } from './types';
 
export const analytics: AnalyticsManager = createAnalyticsManager({
    providers: {
        umami: createUmamiAnalyticsService,
    },
});
